





















































interface ComponentData {
  menus: Array<Record<string, any>>
  currentMenuIndex: number
  successMessage: string
  errorMessage: string
}

import Vue from 'vue'
//import Transaction from './Transaction.vue'

export default Vue.extend({
  data(): ComponentData {
    return {
      successMessage: '',
      errorMessage: '',
      menus: [
        /* {
          name: 'Transactions',
          component: Transaction
        } */
      ],
      currentMenuIndex: 0
    }
  },
  methods: {
    errorMessageEvent(message: string): any {
      console.log('zzzz', message)
      this.errorMessage = message
    },
    successMessageEvent(message: string): any {
      this.successMessage = message
    }
  }
})
